import PropTypes from 'prop-types';

const functionOrArray = PropTypes.oneOfType([PropTypes.func, PropTypes.array]);

export const responsiveAttributePropType = PropTypes.shape({
  default: functionOrArray,
  desktop: functionOrArray,
  desktopExtraLarge: functionOrArray,
  desktopLarge: functionOrArray,
  tablet: functionOrArray,
});
