import styled, { css } from 'styled-components';
import { nuDSColor, hasColorInNuDS, spacing } from '@nubank/nuds-web/styles/themeUtils';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

const blockBackgroundColor = css`
  background-color: ${
  ({ backgroundColor, backgroundColorVariant }) => (hasColorInNuDS(backgroundColor)
    ? nuDSColor(backgroundColor, backgroundColorVariant)
    : backgroundColor)
};
`;

const mediaQueries = {
  default: 'xs',
  tablet: 'sm',
  desktop: 'md',
  desktopLarge: 'lg',
  desktopExtraLarge: 'xl',
};

const blockPadding = ({ padding }) => css`
  /* Default padding on mobile */
  padding: ${spacing('16x')} ${spacing('8x')};
  
  /* Default padding on desktop */
  ${breakpointsMedia({
    md: css`
      padding: ${spacing('16x')};
    `,
  })}

  ${breakpointsMedia(
    Object.keys(padding).reduce(
      (prev, breakpoint) => ({
        ...prev,
        [mediaQueries[breakpoint]]: css`
          ${padding[breakpoint]};
        `,
      }), {},
    ),
  )}
`;

export const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.justifyContent};
  text-align: ${props => props.textAlign};
  position: relative;
  order: ${props => props.mobileOrder};
  align-items: ${props => props.alignItems};

  ${breakpointsMedia({
    md: css`
      width: ${props => props.blockWidth};
      order: unset;
    `,
  })}

  ${blockPadding};
  ${blockBackgroundColor};
`;
