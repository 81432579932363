import PropTypes from 'prop-types';
import colorVariantsShape from '@nubank/nuds-web/styles/colors/colorVariantsShape';

import { responsiveAttributePropType } from 'styles/propTypes';

import { Block } from './style';

Block.defaultProps = {
  children: null,
  alignItems: 'flex-start',
  backgroundColor: undefined,
  backgroundColorVariant: 'default',
  justifyContent: 'center',
  textAlign: 'left',
  blockWidth: '50%',
  mobileOrder: 1,
  padding: {},
};

Block.propTypes = {
  alignItems: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundColorVariant: colorVariantsShape,
  blockWidth: PropTypes.string,
  children: PropTypes.node,
  justifyContent: PropTypes.string,
  mobileOrder: PropTypes.number,
  padding: responsiveAttributePropType,
  textAlign: PropTypes.string,
};

export default Block;
